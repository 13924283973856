import { lazy, Suspense, LazyExoticComponent } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  useParams,
  matchPath,
} from 'react-router-dom';

import Loader from 'components/Loader';
import handleCreateRedirection from 'utils/handleCreateRedirection';
import pathsWithLanguagesCode from 'utils/pathsWithLanguagesCode';

import versions from './versions.json'

const Landings = lazy(() => import('@picsart/landings'));

const routes = pathsWithLanguagesCode();

const Modules: Record<string, LazyExoticComponent<(props?: any) => any>> =  {
  'create-and-home': lazy(
    () => import('@picsart/create-and-home')
  ),
  'careers-front': lazy(
    () => import('@picsart/careers-front')
  ),
  'user-flows': lazy(
    () => import('@picsart/user-flows')
  ),
  'legal-and-press-kit': lazy(
    () => import('@picsart/legal-and-press-kit')
  ),
  'miniapp-container-social': lazy(
    () => import('@picsart/miniapp-container-social')
  ),
  'paid-ua': lazy(
    () => import('@picsart/paid-ua')
  ),
};

const Component = () => {
  const { '*': routeParams } = useParams();
  const configRoute = `${Object.keys(routes).find((pathname: string) => matchPath(pathname, `/${routeParams}`))}`;
  const moduleName = handleCreateRedirection(routeParams, routes[configRoute]);
  const version = versions[(moduleName || 'landings') as keyof typeof versions];

  if (moduleName && Modules[moduleName]) {
    const Module = Modules[moduleName];
    return (
      <Suspense fallback={<Loader />}>
        <Module version={version}/>
      </Suspense>
    );
  }

  return (
    <Suspense fallback={null}>
      {/*@ts-ignore*/}
      <Landings version={version}/>
    </Suspense>
  );
};

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<Component />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
